import React, { Component } from 'react';
import { Input, Checkbox, ListItemText, Select, FormControl, TextField, MenuItem, InputLabel, FormHelperText, Button } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { styled } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';

const Container = styled(FormControl)({
    width: '100%',
    marginBottom: '10px'    
  });
const ContainerDropzone = styled(`section`)({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    maxWidth: '408px'
  });
const ContainerFiles = styled(`ul`)({
    fontSize: '0.6em',
    maxWidth: '408px'    
  });
const FileItem = styled(`li`)({    
    maxWidth: '408px',
    marginRight: '15px'    
  });
const ContainerForSelect = styled(Select)({
    maxWidth: '408px'    
});   
const ContainerForButton = styled(Button)({
    maxWidth: '53px',
    maxHeigth: '21px',
    textAlign: 'center',
    fontSize: '9px',
    color: 'red'
})


export default class FormItem extends Component {


    componentDidMount() {
        if (this.props.jiraSchema.type === 'priority') {
            this.props.changeItem(this.props.index, this.props.validValues[0].value)
        }
        if (this.props.jiraSchema.type === 'date') {
            this.props.changeItem(this.props.index, new Date());
        }
    }
    
    onValueChange = (e) => {
        this.props.changeItem(this.props.index, e.target.value);
    };

    onDateChange = (e) => {
        this.props.changeItem(this.props.index, e);
    };

    onChange(e){
        let files=e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload=(e)=>{
            console.log("img data", e.target.result);
        }
    }


    render() {
        const { jiraSchema, validValues } = this.props;
        switch (jiraSchema.type) {
            case 'string' :
                let description;
                if (jiraSchema.system !== 'description')
                    description = false;
                else 
                    description = true;
                return (
                    <Container>
                        <TextField label={this.props.name}
                            error={this.props.error}       
                            multiline={description}                                                 
                            required={this.props.required}
                            onChange={this.onValueChange}
                            defaultValue=''></TextField>
                            { this.props.error ? <FormHelperText error={this.props.error}>Please provide a valid value for field '{this.props.name}'</FormHelperText> : null }
                        </Container>
                    )

            case 'option' :
            case 'priority' :
                return (
                    <Container>
                        <InputLabel key={`${this.props.name}.inputLabel`} error={this.props.error} required={this.props.required}>{this.props.name}</InputLabel>
                        <ContainerForSelect            
                        defaultValue={this.props.value}
                        onChange={this.onValueChange}>
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                        {validValues.map(elem=>
                            <MenuItem value={elem.value}>{elem.label}</MenuItem>)}
                        </ContainerForSelect>
                        { this.props.error ? <FormHelperText error={this.props.error}>Please provide a valid value for field '{this.props.name}'</FormHelperText> : null }
                </Container>
                )

            case 'date' :
                return (
                    <Container>                    
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            error={this.props.error}
                            required={this.props.required}
                            variant="inline"
                            clearable
                            disablePast
                            label={this.props.name}
                            value={this.props.value}
                            format="dd/MM/yyyy"                 
                            onChange={this.onDateChange}          
                        />                        
                        </MuiPickersUtilsProvider>    
                        { this.props.error ? <FormHelperText error={this.props.error}>Please provide a valid value for field '{this.props.name}'</FormHelperText> : null }      
                </Container>
                )
            case 'array' :
                if (jiraSchema.system === 'attachment') {
                    return (
                        <Container>                            
                        <Dropzone onDrop={files => {
                            files.forEach(file => {
                                const reader = new FileReader();
                                reader.onload = () => {                                                                                                              
                                      const binaryStr = reader.result;                        
                                      this.props.addAttachFile(binaryStr, file, this.props.index);                                      
                                    }
                                reader.readAsArrayBuffer(file);
                            })
                        }}>
                        {({getRootProps, getInputProps}) => (
                            <ContainerDropzone>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <h5>Drag 'n' drop some files here, or click to select files</h5>
                            </div>
                            <aside>
                                <h6>Files:</h6>
                                <ContainerFiles>{this.props.attachments.map((file, index) => (
                                    <div>
                                    <FileItem key={file.info.name}>
                                    {file.info.name} - {file.info.size} bytes
                                    </FileItem>
                                    <ContainerForButton onClick={this.props.removeAttachFile.bind(this, index)}>remove</ContainerForButton>
                                    </div>
                                    ))}
                                </ContainerFiles>
                            </aside>    
                            </ContainerDropzone>
                        )}
                        </Dropzone>
                        { this.props.error ? <FormHelperText error={this.props.error}>Please provide a valid value for field '{this.props.name}'</FormHelperText> : null } 
                        </Container> 
                    )
                }
                else if (jiraSchema.system === null) {  
                return (
                    <Container>
                        <InputLabel error={this.props.error} required={this.props.required}>{this.props.name}</InputLabel>          
                        <ContainerForSelect
                        error={this.props.error}                        
                        multiple                        
                        value={this.props.value}
                        onChange={this.onValueChange}
                        input={<Input />}
                        renderValue={selected => selected.join(', ')}
                        >
                        { this.props.validValues.length===0 ? 
                            <MenuItem value=''>
                                <em>None</em>
                            </MenuItem> : null }
                        {validValues.map(elem=>
                            <MenuItem
                            value={elem.label}
                            >
                                <Checkbox checked={this.props.value.indexOf(elem.label) > -1} />
                                <ListItemText primary={elem.label} />        
                            </MenuItem>)}
                        </ContainerForSelect>
                        { this.props.error ? <FormHelperText error={this.props.error}>Please provide a valid value for field '{this.props.name}'</FormHelperText> : null }                        
                    </Container>                    
                )
                }
                else {
                    return (
                        <Container>
                            <InputLabel required={this.props.required} error={this.props.error}>{this.props.name} </InputLabel>          
                            <ContainerForSelect
                            multiple                            
                            value={this.props.value}
                            onChange={this.onValueChange}
                            input={<Input />}
                            >
                            { this.props.validValues.length===0 ? 
                            <MenuItem value=''>
                                <em>None</em>
                            </MenuItem> : null }
                            {validValues.map(elem=>
                                <MenuItem key={elem.value} value={elem.value} >
                                {elem.label}
                              </MenuItem>
                              )}
                            </ContainerForSelect>
                            { this.props.error ? <FormHelperText error={this.props.error}>Please provide a valid value for field '{this.props.name}'</FormHelperText> : null }
                        </Container>                    
                    )
                }
            default :
        return <span>need to do</span>
        }
    }
}
