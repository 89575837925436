import React, { Component } from 'react';
import FormList from './FormList';
import { styled } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';

const SubmitButton = styled(Button)({
    margin: '10px'
});
const Container = styled(Box)({
    width: '450px',
    marginBottom: '10px'
});
const BoxSuccess = styled(Box)({
    color: 'green',
    fontSize: '16px'
});
const BoxError = styled(Box)({
    color: 'red',
    fontSize: '16px'
});

const initialState = {
    data:[],
    attachments : [
    ],
    visible: false,
    requestTypeId: '',
    success: null,
    submitting: false
}


class PostForm extends Component {


    constructor(props) {
        super(props)
        this.state = initialState;

        this.submitHandler = this.submitHandler.bind(this);
        this.getForms = this.getForms.bind(this);
        this.getTypes = this.getTypes.bind(this);
    }
    
    componentDidMount() {     
        this.getState()
    }

    validationFunc = (response, self) => {
        if (response.status===200 && response.url===`${process.env.REACT_APP_API_URL}/api/Ticket/Create`){
            let success = true;
            self.setState({success});
            self.getState();
            return response.json();
        } 
        if (response.status>=200 && response.status<300){
            return response.json();                                                                                                                    
        }
        else {
            let success = false;
            self.setState({success});
            return                    
        }                
}

    getState = () => {
        if (this.props.requesttype === undefined)       
        this.getTypes();
        else
        this.getForms();
    }

    getTypes = () => {
        let self = this;
        let submitting;   
        this.reset(true);
        submitting = true;
        this.setState({submitting});  
        fetch(`${process.env.REACT_APP_API_URL}/api/Ticket/GetRequestTypes?servicedeskId=${this.props.servicedeskId}`)         
        .then(function(response)
        {
            return response.json();
        })
        .then(result => {
            if (result !== undefined){
            submitting = false;
            this.setState({submitting});
            this.addFormItem("Choose request type", {type:"option"}, 'requestTypes', result.values.map(elem =>{
                let newElem = {};
                newElem.value = elem.id;
                newElem.label = elem.name;
                return newElem;
            }), 0, false, [], false)
            }
        })
        .catch(ex=>{
            return self.validationFunc(ex, self);
        })
    }

    getForms = () => {
        let self = this;    
        let submitting;
        let requestTypeId;
        if (this.props.requesttype === undefined)
            requestTypeId = this.state.data[0].value;
        else 
            requestTypeId = this.props.requesttype        
        const urlGetForms = `${process.env.REACT_APP_API_URL}/api/Ticket/GetForms?servicedeskId=${this.props.servicedeskId}&requesttype=${requestTypeId}`         
        if (requestTypeId!==''){
            this.setState({requestTypeId});
            submitting = true;
            this.setState({submitting});                     
            fetch(urlGetForms)
                .then(function(response)
                {
                    return self.validationFunc(response, self);  
                })
                .then(result => {
                    if (result !== undefined)
                    {
                        if (this.props.requesttype !== undefined)
                        this.reset(true);
                        else
                        this.reset();
                        submitting = false;
                        this.setState({submitting});
                        let visible = true;
                        this.setState({visible});
                        this.addFormItem("Email Address", {type:"string"}, 'emailForm', '', 0, true, [], false);                
                        result.requestTypeFields.forEach ((element, index) => {
                            this.addFormItem(element.name, element.jiraSchema, element.fieldId, element.validValues, index+1, element.required, [], false)
                        });                       
                    }                 
                })
                .catch(ex=>{
                    this.reset();
                });                                                                         
        }
        else return
    }

    reset = (successFlag) => {
        const { requestTypeId, success} = this.state;
        this.setState(initialState);
        this.setState({requestTypeId});
        if (successFlag !== undefined)
        this.setState({success});
    }

    validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    createFormItem = (name, jiraSchema, fieldId, validValues, index, required, value, error) => {
        return {
            name,
            jiraSchema,
            fieldId,
            validValues,
            index,
            required,
            value,
            error
        };
    }

    arrayBufferToBase64 = ( buffer ) => {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    changeItem = (index, newValue) => {
        let data = [...this.state.data];
        let item = {...data[index]};
        if (newValue !== '' || item.required === false)
        {
        item.error = false
        item.value = newValue;
        data[index] = item;
        this.setState({data});        
        }
    }

    errorHandler = (item, index) => {   
        let data = [...this.state.data];     
        item.error = true                
        data[index] = item;
        this.setState({data});
        console.log(this.state.data); 
    }

    addAttachFile = (newValue, fileInf, index) => {
        let attachments = [...this.state.attachments];
        let attachment = {};
        attachment.base64 = newValue;
        attachment.info = fileInf;
        attachments.push(attachment);
        this.setState({attachments});
        let data = [...this.state.data];
        let item = {...data[index]};     
        item.error = false;                
        data[index] = item;
        this.setState({data});
    }

    removeAttachFile = (index) => {
        this.setState(({attachments})=>{
            const elem = attachments.findIndex((el, indexEl) => indexEl===index);
            const newArr = [
                ...attachments.slice(0,elem), 
                ...attachments.slice(elem+1)
            ];
            return {
                attachments : newArr
            }
        });
    }

    addFormItem = (name, jiraSchema, fieldId, validValues, index, required, value, error) => {
        const newItem = this.createFormItem(name, jiraSchema, fieldId, validValues, index, required, value, error);
        this.setState(({data}) => {
            const newArr = [...data, newItem];
            return {
                data:newArr
            };
        })
    };


    submitHandler = () => {
        let submitting;
        let self = this
        if (this.state.data[0].fieldId === 'requestTypes'){
            if(this.state.data[0].value !== '' || this.state.data[0].value.length!==0)
            this.getForms();
            else
            return
        }
        else {
        //let visibleBackButton = true;
        //this.setState(visibleBackButton);
        const url = `${process.env.REACT_APP_API_URL}/api/Ticket/Create`;
        const urlAttach = `${process.env.REACT_APP_API_URL}/api/Ticket/Attachment`;        
        let dataPost = {
            fields : []
        };
        let dataAttach = {
            attachments : []
        };
        let validation = false;
        dataPost.requestTypeId = this.state.requestTypeId;                
        this.state.data.forEach((element, indx) => {
            if ((element.required === true && element.value.length===0 && element.jiraSchema.system !== 'attachment') ||
            (element.required === true && element.value.length<2 && element.value[0] === '')) {                              
                validation = true;
                this.errorHandler(element, indx)
            }            
            if (element.fieldId === 'emailForm' && element.error === false) {
                let validateEmail = this.validateEmail(element.value)
                if (validateEmail === false) {
                validation = true;
                this.errorHandler(element, indx)
                }
            }
            if (validation)
                return
            const {index,  error, ...submitData} = element;
            dataPost.serviceDeskId = this.props.servicedeskId;
            if (submitData.jiraSchema.type === 'date'){
                submitData.value = submitData.value.toISOString().substring(0,10);
            }
            if ((submitData.jiraSchema.system !=='attachment' && submitData.fieldId !=='emailForm') && 
                (submitData.value.length > 0 && submitData.value[0] !== '' && submitData.value !== ''))
            {
                dataPost.fields.push(submitData);
            }
            else if (submitData.fieldId ==='emailForm'){
                dataPost.email = submitData.value;
            }            
        });
        if (validation)
            return        
        this.state.attachments.forEach(element => {
            let attachment = {};
            attachment.base64 = this.arrayBufferToBase64(element.base64);
            attachment.info = {};
            attachment.info.name = element.info.name;
            attachment.info.type = element.info.type;
            attachment.info.path = element.info.path;
            dataAttach.attachments.push(attachment);          
        });
        submitting = true;
        this.setState({submitting});       
            fetch(url, {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
            'Content-Type': 'application/json'
            }
            }).then(function(response) { 
                return self.validationFunc(response, self);                
            }).then(function(data) {                
                dataAttach.key = data.key;
            if (dataAttach.attachments.length>0){               
                fetch(urlAttach, {
                    method: 'POST',
                    body: JSON.stringify(dataAttach),
                    headers: {
                        'Content-Type': 'application/json'
                        }
                }).then(function(response) {                    
                    return response.json();                             
                })
                }                
            })                              
            .catch(ex => {
                self.validationFunc(ex, self);
            });
    }       
};

    render() {
        const { data, attachments } = this.state

        const formsData = data;


        return (
            <Container className="post-form list-group-item" onSubmit={this.submitHandler}>
                { this.state.success ? <BoxSuccess>Thank you for submitting your request.</BoxSuccess> : this.state.success === false ? <BoxError>Something went wrong</BoxError> : null }  
                    <FormList
                        changeItem={this.changeItem}
                        addAttachFile={this.addAttachFile}
                        removeAttachFile={this.removeAttachFile}
                        forms={formsData}
                        attachments={attachments}/>                    
                    { this.props.requesttype? null : this.state.visible ? <Button onClick={this.getState}>Back</Button> : null }
                    { ((this.state.success === true) || (this.state.success === null)) ? <SubmitButton disabled={this.state.submitting ? true : false} onClick={this.submitHandler}>Submit</SubmitButton> : null}
            </Container>
        )
    }
}
 
export default PostForm;