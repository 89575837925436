import React from 'react';
import './App.css';
import PostForm from './components/PostForm';
import { Route } from 'react-router-dom';

function App() {
  return ( 
    <div className="App">
      <header className="App-header">
        <Route path="/:servicedeskId" exact
        render={({match}) => {
          const {servicedeskId} = match.params
          return <PostForm servicedeskId={servicedeskId}/>
        }}/>  
        <Route path="/:servicedeskId/:requesttype" 
        render={({match}) => {
          const {servicedeskId, requesttype} = match.params         
          return <PostForm servicedeskId={servicedeskId} requesttype={requesttype}/>
        }}/>       
        </header>
    </div>
 
  );
}

export default App;
