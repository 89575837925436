import React from 'react';
import FormItem from './FormItem';

const FormList = ( { forms, changeItem, addAttachFile, removeAttachFile, attachments }) => {

    const elements = forms.map((item) => {
        const {...props} = item;
        return (
            <li> 
                <FormItem key = {item.name}
                {...props}
                changeItem = {changeItem}
                addAttachFile= {addAttachFile}
                removeAttachFile= {removeAttachFile}
                attachments={attachments} />
            </li>
        );
    });

    return (
        <div>
            { elements }
        </div>
    );
};

export default FormList;